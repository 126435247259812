import React from "react"
import { graphql } from "gatsby"
import {Helmet} from "react-helmet";
import ArchiveCore from "../components/ArchiveCore"

const ArchiveCat = (props) => {
  const { data } = props
  return(
    <>
      <Helmet>
        <title>{data.wpCategory.seo.title}</title>
      </Helmet>
      <ArchiveCore props={props} title={`${data.wpCategory.name} Post Archives`} />
    </>
  )
}


export const query = graphql`
query ArchiveCategory($offset: Int, $perPage: Int, $termId: String = "") {
    wp {
      generalSettings {
        title
      }
    }
    allWpPost(
      limit: $perPage,
      skip: $offset,
      filter: {
        terms: {
          nodes: {
            elemMatch: {
              id: {
                eq: $termId
              }
            }
          }
        }
      },
      sort: {fields: date, order: DESC})
    {
      nodes {
        slug
        uri
        title
        featuredImage {
          node {
            altText
            mediaDetails {
              sizes {
                file
                height
                width
                sourceUrl
                name
              }
            }
          }
        }
        excerpt
        categories {
          nodes {
            uri
            name
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        currentPage
        pageCount
      }
    }

  wpCategory(id: {eq: $termId}) {
    name
    description
    seo {
      title
      opengraphTitle
      metaDesc
      opengraphUrl
    }
  }
}
`

export default ArchiveCat
